import { useEffect, useMemo, useRef } from 'react';

import lodashDebounce from 'lodash/debounce';

export function useDebounceHandler<T extends (...args: any) => any>(
  callback: T,
  ms = 300,
) {
  const cbRef = useRef<T | null>(null);

  useEffect(() => {
    cbRef.current = callback;
  }, [callback]);

  const debouncedCallback = useMemo(() => {
    const func = (...args: any[]) => {
      cbRef.current?.(...args);
    };
    return lodashDebounce(func, ms);
  }, [ms]);

  return debouncedCallback;
}
