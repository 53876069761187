import { Fragment, useRef } from 'react';

import { Input as BaseInput } from '@mui/base/Input';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';

import { toCssString } from '@/utils/styles';

const StyledOtpInput = styled('input')`
  width: 57px;
  height: 64px;
  border-radius: 10px;
  border: none;
  background-color: ${({ theme }) => theme.palette.neutral[5]};
  color: ${({ theme }) => theme.palette.text[0]};
  caret-color: ${({ theme }) => theme.palette.text[0]};
  ${({ theme }) => toCssString(theme.typography.largeText2)};
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  outline: none;
  text-align: center;

  &:focus {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.palette.neutralV2[2]};
  }

  &:focus-within {
    outline: none;
  }
`;

interface IAppOtpInput {
  separator?: React.ReactNode;
  length: number;
  value?: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  error?: boolean;
}
function AppOtpInput({
  separator,
  length,
  value = '',
  onChange,
  disabled,
  error,
}: IAppOtpInput) {
  const inputRefs = useRef<HTMLInputElement[]>(Array.from({ length }));

  const focusInput = (targetIndex: number) => {
    const targetInput = inputRefs.current[targetIndex];
    targetInput.focus();
  };

  const selectInput = (targetIndex: number) => {
    const targetInput = inputRefs.current[targetIndex];
    targetInput.select();
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
    currentIndex: number,
  ) => {
    switch (event.key) {
      case 'ArrowUp':
      case 'ArrowDown':
      case ' ':
        event.preventDefault();
        break;
      case 'ArrowLeft':
        event.preventDefault();
        if (currentIndex > 0) {
          focusInput(currentIndex - 1);
          selectInput(currentIndex - 1);
        }
        break;
      case 'ArrowRight':
        event.preventDefault();
        if (currentIndex < length - 1) {
          focusInput(currentIndex + 1);
          selectInput(currentIndex + 1);
        }
        break;
      case 'Delete':
        event.preventDefault();

        onChange(value.slice(0, currentIndex) + value.slice(currentIndex + 1));

        break;
      case 'Backspace':
        event.preventDefault();
        if (currentIndex > 0) {
          focusInput(currentIndex - 1);
          selectInput(currentIndex - 1);
        }

        onChange(value.slice(0, currentIndex) + value.slice(currentIndex + 1));
        break;

      default:
        break;
    }
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    currentIndex: number,
  ) => {
    const currentValue = event.target.value;
    let indexToEnter = 0;

    while (indexToEnter <= currentIndex) {
      if (
        inputRefs.current[indexToEnter].value &&
        indexToEnter < currentIndex
      ) {
        indexToEnter += 1;
      } else {
        break;
      }
    }

    const otpArray = value.split('');
    const lastValue = currentValue[currentValue.length - 1];
    otpArray[indexToEnter] = lastValue;
    const nextOtp = otpArray.join('');
    onChange(nextOtp);
    if (currentValue !== '') {
      if (currentIndex < length - 1) {
        focusInput(currentIndex + 1);
      }
    }
  };

  const handleClick = (
    _: React.MouseEvent<HTMLInputElement, MouseEvent>,
    currentIndex: number,
  ) => {
    selectInput(currentIndex);
  };

  const handlePaste = (
    event: React.ClipboardEvent<HTMLInputElement>,
    currentIndex: number,
  ) => {
    event.preventDefault();
    const clipboardData = event.clipboardData;

    // Check if there is text data in the clipboard
    if (clipboardData.types.includes('text/plain')) {
      let pastedText = clipboardData.getData('text/plain');
      pastedText = pastedText.substring(0, length).trim();
      let indexToEnter = 0;

      while (indexToEnter <= currentIndex) {
        if (
          inputRefs.current[indexToEnter].value &&
          indexToEnter < currentIndex
        ) {
          indexToEnter += 1;
        } else {
          break;
        }
      }

      const otpArray = value.split('');

      for (let i = indexToEnter; i < length; i += 1) {
        const lastValue = pastedText[i - indexToEnter] ?? ' ';
        otpArray[i] = lastValue;
      }

      onChange(otpArray.join(''));
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 1,
        justifyContent: 'space-evenly',
        ['.Mui-error input']: {
          boxShadow: (t) => `0 0 0 1px ${t.palette.semantic.negative100}`,
        },
      }}>
      {Array.from({ length }, (_, index) => (
        <Fragment key={index}>
          <BaseInput
            slots={{
              input: StyledOtpInput,
            }}
            slotProps={{
              input: {
                disabled,
                ref: (ele) => {
                  inputRefs.current[index] = ele!;
                },
                onKeyDown: (event) => handleKeyDown(event, index),
                onChange: (event) => handleChange(event, index),
                onClick: (event) => handleClick(event, index),
                onPaste: (event) => handlePaste(event, index),
                value: value[index] ?? '',
              },
            }}
          />
          {index === length - 1 ? null : separator}
        </Fragment>
      ))}
    </Box>
  );
}

export default AppOtpInput;
