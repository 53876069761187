import Container from '@mui/material/Container';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export default function OtpLoadingPage() {
  return (
    <Container maxWidth="md" sx={{ height: '100%' }}>
      <Stack
        alignItems="center"
        justifyContent="center"
        width="100%"
        height="100%">
        <Stack
          width="430px"
          paddingX="46px"
          alignItems="center"
          justifyContent="center"
          gap="40px"
          component="form">
          <Typography variant="h1">Email verification</Typography>
          <Stack flexDirection="row" gap="16px">
            <Skeleton variant="rounded" width={48} height={64} />
            <Skeleton variant="rounded" width={48} height={64} />
            <Skeleton variant="rounded" width={48} height={64} />
            <Skeleton variant="rounded" width={48} height={64} />
            <Skeleton variant="rounded" width={48} height={64} />
          </Stack>
          <Skeleton variant="rounded" width="100%" height={60} />
        </Stack>
      </Stack>
    </Container>
  );
}
