'use client';

import { formControlClasses as formControlUnstyledClasses } from '@mui/base/FormControl';
import { inputClasses as inputUnstyledClasses } from '@mui/base/Input';

export const SHARED_NO_ERROR_SX = {
  minWidth: 0,
  [`&.${formControlUnstyledClasses.focused}`]: {
    [`& .${inputUnstyledClasses.root} .${inputUnstyledClasses.input}`]: {
      backgroundColor: 'transparent',
    },
    [`&:not(.${formControlUnstyledClasses.error})`]: {
      [`& .${inputUnstyledClasses.root} .${inputUnstyledClasses.input}`]: {
        boxShadow: 'none',
      },
    },
  },
};
