'use client';

import type {
  FormControlProps as FormControlUnstyledProps,
  FormControlState as FormControlUnstyledState,
} from '@mui/base/FormControl';
import type { InputProps as InputUnstyledProps } from '@mui/base/Input';
import type { SxProps, Theme } from '@mui/material/styles';

import React, { useCallback, useId, useMemo, useState } from 'react';

import {
  FormControl as FormControlUnstyled,
  formControlClasses as formControlUnstyledClasses,
} from '@mui/base/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import { styled } from '@mui/material/styles';

import { calculateTextLenWithKorean, findMaxIndex } from '@/utils/string';
import { AppHelperText } from '@components/TextField/HelperText.component';
import {
  APP_FORM_CONTROL_INPUT_ROOT_CLASS_NAME,
  AppInput,
} from '@components/TextField/Input.component';
import { AppLabel } from '@components/TextField/Label.component';

type InputRenderFn = (props: {
  charactersLeft: number | null;
}) => React.ReactNode;

export interface AppFormControlProps extends FormControlUnstyledProps {
  sx?: SxProps<Theme>;
  label?: React.ReactNode;
  helperText?: React.ReactNode;
  placeholder?: string;
  maxLength?: number;
  InputProps?: InputUnstyledProps;
  renderInput?: InputRenderFn;
  value?: string;
  showRequired?: boolean;
}

const StyledFormControl = styled(FormControlUnstyled, {
  shouldForwardProp: (propName) => {
    return propName !== 'sx';
  },
})`
  &.${formControlUnstyledClasses.focused} {
  }
`;

export function AppFormControl({
  sx,
  label,
  helperText,
  placeholder,
  maxLength,
  onChange,
  InputProps,
  renderInput,
  value,
  showRequired,
  ...props
}: AppFormControlProps) {
  const maxCharacters = maxLength ?? null; // NOTE: respect zero value
  const inputId = useId();

  const inputValue = value || '';
  const charactersLeft =
    maxCharacters != null
      ? maxCharacters - calculateTextLenWithKorean(inputValue)
      : null;

  const maxCharacterIndex =
    maxCharacters != null
      ? findMaxIndex(inputValue, calculateTextLenWithKorean, maxCharacters)
      : null;

  const trimmedValue = useMemo(
    () =>
      maxCharacterIndex != null
        ? inputValue.slice(0, maxCharacterIndex)
        : inputValue,
    [inputValue, maxCharacterIndex],
  );

  const [isFocusing, setFocusing] = useState(false);

  const characterLimited = maxCharacters != null;

  const hasCustomInput = typeof renderInput !== 'undefined';

  const onChangeHandler: React.ChangeEventHandler<HTMLInputElement> =
    useCallback(
      (e) => {
        if (!characterLimited) {
          onChange?.(e);
          return;
        }

        const maxIndex = findMaxIndex(
          e.currentTarget.value,
          calculateTextLenWithKorean,
          maxCharacters,
        );

        const nextValue = e.currentTarget.value.slice(0, maxIndex);
        e.currentTarget.value = nextValue;
        onChange?.(e);
      },
      [maxCharacters, characterLimited, onChange],
    );

  return (
    <StyledFormControl
      sx={sx}
      onChange={onChangeHandler}
      value={trimmedValue}
      {...props}
      //
    >
      {(_: FormControlUnstyledState) => (
        <>
          {label ? (
            <AppLabel inputId={inputId} showRequired={showRequired}>
              {label}
            </AppLabel>
          ) : null}

          {hasCustomInput ? (
            renderInput({ charactersLeft })
          ) : (
            <AppInput
              id={inputId}
              onFocus={() => setFocusing(true)}
              onBlur={() => setFocusing(false)}
              //
              className={APP_FORM_CONTROL_INPUT_ROOT_CLASS_NAME}
              //
              placeholder={placeholder}
              endAdornment={
                characterLimited && isFocusing ? (
                  <InputAdornment
                    className={
                      charactersLeft != null && charactersLeft < 0
                        ? 'Cigro-exceeded'
                        : ''
                    }
                    position="end"
                    //
                  >
                    {charactersLeft}
                  </InputAdornment>
                ) : null
              }
              {...(InputProps ? InputProps : {})}
            />
          )}

          <AppHelperText>{helperText}</AppHelperText>
        </>
      )}
    </StyledFormControl>
  );
}
