'use client';

import type { AppFormControlProps } from '@components/TextField/FormControl.component';
import type { SxProps, Theme } from '@mui/material/styles';
import type {
  ControllerProps,
  FieldValue,
  FieldValues,
  RegisterOptions,
} from 'react-hook-form';

import React from 'react';

import { Controller } from 'react-hook-form';

import { AppFormControl } from '@components/TextField/FormControl.component';

import { APP_FORM_CONTROL_INPUT_ROOT_CLASS_NAME } from './Input.component';

export interface AppTextFieldProps<
  Schema extends FieldValues,
  FieldName extends FieldValue<Schema>,
> extends Omit<AppFormControlProps, 'value' | 'name'> {
  showRequired?: boolean;
  label?: React.ReactNode;
  helperText?: React.ReactNode;
  maxLength?: number;
  control: ControllerProps<Schema, FieldName>['control'];
  name: ControllerProps<Schema, FieldName>['name'];
  sx?: SxProps<Theme>;
  rules?: Omit<
    RegisterOptions<Schema, FieldName>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  >;
}

function AppTextField<
  Schema extends FieldValues,
  FieldName extends FieldValue<Schema>,
>({
  sx,
  // NOTE: Controller props
  name,
  control,
  maxLength,
  required,
  showRequired,
  rules,
  // NOTE: Input props
  placeholder,
  // NOTE: form control props
  onChange: onChangeProps,
  onBlur: onBlurProps,
  InputProps,
  ...formControlProps
}: AppTextFieldProps<Schema, FieldName>) {
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required,
        maxLength,
        ...rules,
      }}
      render={({ field: { value = '', ref, name, onBlur, onChange } }) => (
        <AppFormControl
          value={value}
          maxLength={maxLength}
          required={required}
          showRequired={showRequired}
          InputProps={{
            ...InputProps,
            ref,
            name,
            placeholder,
          }}
          onChange={(e) => {
            onChange(e);
            onChangeProps?.(e);
          }}
          onBlur={(e) => {
            onBlur();
            onBlurProps?.(e);
          }}
          sx={sx}
          {...formControlProps}
          //
        />
      )}
    />
  );
}

export default AppTextField;
