import { I18n, i18n } from 'next-i18next';
import z from 'zod';

import axiosHttpClient from '@/adapters/api/axios/axios-http-client';
import { zDashboardPermissions, zTeamPermissions } from '@/models/user';
import { to } from '@/utils/async';
import {
  koreanStrLengthCalculator,
  phoneNumberZodSchema,
  strlen,
  zEnglishKoreanStringSchema,
} from '@/utils/string';

function getCookieHeaderConfig(
  cookies?: Partial<{
    [key: string]: string;
  }>,
) {
  let cookiesStrs: string[] = [];

  if (cookies) {
    cookiesStrs = cookiesStrs.concat(
      Object.entries(cookies)?.map(([key, value]) => `${key}=${value}`),
    );
  }

  return cookiesStrs.length
    ? {
        headers: {
          Cookie: cookiesStrs,
        },
        withCredentials: true,
      }
    : undefined;
}
/**
 * @deprecated uses `getSignInPayloadSchema()` instead
 */
export const SignInPayloadSchema = z.object({
  email: z
    .string({
      required_error: i18n?.t('page-sign-in.email-required'),
    })
    .email(i18n?.t('page-sign-in.invalid-email')),
  otp_code: z
    .string()
    .length(5, i18n?.t('page-otp.otp-length-eq-5'))
    .optional(),
});

export const getSignInPayloadSchema = (i18n: I18n) =>
  z.object({
    email: z
      .string({
        required_error: i18n.t('page-sign-in.email-required'),
      })
      .email(i18n.t('page-sign-in.invalid-email')),
    otp_code: z
      .string({
        required_error: i18n.t('page-otp.otp-length-eq-5'),
      })
      .length(5, i18n.t('page-otp.otp-length-eq-5'))
      .optional(),
  });

export type SignInPayload = z.infer<typeof SignInPayloadSchema>;

export const SignInResponseSchema = z.object({
  access_token: z.string(),
  refresh_token: z.string(),
  member_id: z.string(),
  access_token_expires_at: z.string(),
  new_signed_up: z.boolean(),
});

export type SignInResponse = z.infer<typeof SignInResponseSchema>;

export const signinAPI_deprecated = async (
  { email, otp_code }: SignInPayload,
  refToken?: string,
) => {
  const response = await axiosHttpClient.post<SignInResponse>(
    '/auth/users/tokens',
    {
      email,
      otp_code,
    },
    {
      headers: {
        Cookie: 'refresh_token=' + refToken,
      },
    },
  );

  const { success } = SignInResponseSchema.safeParse(response?.data);

  if (!success) return null;

  // NOTE: return raw response since we need to get cookie from res.header
  return response;
};

export const serverSideRefreshTokenAPI_deprecated = async (
  cookies: Partial<{
    [key: string]: string;
  }>,
) => {
  const cookieHeader = getCookieHeaderConfig(cookies);
  const [response, apiErr, unknownErr] = await to(() =>
    axiosHttpClient.put<SignInResponse>('/auth/users/tokens', {}, cookieHeader),
  );
  if (unknownErr) throw unknownErr;
  if (apiErr) throw apiErr;
  const { success } = SignInResponseSchema.safeParse(response?.data);
  if (!success) return null;
  // NOTE: return raw response since we need to get cookie from res.header
  return response;
};

export const serverSideSignOutAPI_deprecated = async (
  cookies: Partial<{
    [key: string]: string;
  }>,
) => {
  const cookieHeader = getCookieHeaderConfig(cookies);
  const [response, apiErr, unknownErr] = await to(() =>
    axiosHttpClient.delete<SignInResponse>('/auth/users/tokens', cookieHeader),
  );
  if (unknownErr) throw unknownErr;
  if (apiErr) throw apiErr;
  return response;
};

export type SignUpPayload = z.infer<typeof SignUpPayloadSchema>;

function defineSchema(cb: (i18n: I18n) => z.ZodSchema) {
  return cb;
}

const zEmailSchema = z
  .string({
    message: i18n?.t('page-sign-up.sign-up-invalid-email-required'),
  })
  .email(i18n?.t('page-sign-up.sign-up-invalid-email'));

/**
 * @deprecated uses `getSignUpPayloadSchema()` instead
 */
export const SignUpPayloadSchema = z.object({
  email: zEmailSchema,
  username: z.string().nullish(),
});

export const getSignUpPayloadSchema = (i18n: I18n) =>
  z.object({
    email: z
      .string({
        message: i18n.t('page-sign-up.sign-up-invalid-email-required'),
      })
      .email(i18n.t('page-sign-up.sign-up-invalid-email')),
    username: z.string().nullish(),
  });

export type CreateProfilePayload = z.infer<typeof CreateProfilePayloadSchema>;

export const CreateProfilePayloadSchema = z.object({
  email: zEmailSchema.nullish(),
  username: z.string({ required_error: 'sign-up-username-required' }).min(1, {
    message: 'sign-up-username-required',
  }),
  company_name: z.string().nullish(),
  role: z.string().nullish(),
  phone_number: phoneNumberZodSchema.min(1, {
    message: 'sign-up-mobile-required',
  }),
});

export const GetPermissionsResponseSchema = z.object({
  team_permissions: z
    .array(
      z.object({
        team_id: z.string().nullish(),
        permission: zTeamPermissions.nullish(),
      }),
    )
    .nullish(),
  dashboard_permissions: z
    .array(
      z.object({
        dashboard_id: z.string().nullish(),
        permission: zDashboardPermissions.nullish(),
      }),
    )
    .nullish(),
});

export type GetPermissionsResponse = z.infer<
  typeof GetPermissionsResponseSchema
>;
