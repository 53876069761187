import React from 'react';

import { useRouter } from 'next/router';

import { Typography } from '@mui/material';
import { signIn } from 'next-auth/react';
import { useTranslation } from 'next-i18next';

import AppButton from '@/components/Button';
import AppIcon from '@/components/Icon';

interface IGoogleLoginBtn {
  callbackUrl?: string;
  onClick?: () => void;
}
const GoogleLoginBtn = ({ callbackUrl, onClick }: IGoogleLoginBtn) => {
  const { t } = useTranslation('common');
  const router = useRouter();
  const callbackLink = `${window.location.origin}${
    callbackUrl || router.asPath
  }`;

  return (
    <AppButton
      onClick={() => {
        onClick?.();
        signIn('google', {
          callbackUrl: callbackLink,
        });
      }}
      variant="text-outlined"
      fullWidth
      sx={{
        height: 36,
        gap: 2,
        justifyContent: 'center',
      }}>
      <AppIcon name="ic_google" size={20} />

      <Typography variant="h5" color="text.0">
        {t('continue-with-google')}
      </Typography>
    </AppButton>
  );
};

export default GoogleLoginBtn;
