'use client';

import React, { useEffect, useState } from 'react';

import { useFormControlContext } from '@mui/base/FormControl';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { toCssString } from '@/utils/styles';

export const AppHelperText = styled(
  ({
    children,
    className,
  }: {
    children?: React.ReactNode;
    className?: string;
  }) => {
    const formControlContext = useFormControlContext();
    const [dirty, setDirty] = useState(false);

    useEffect(() => {
      if (formControlContext?.filled) {
        setDirty(true);
      }
    }, [formControlContext]);

    if (formControlContext === undefined) {
      return null;
    }

    const { required, filled } = formControlContext;

    const showRequiredError = dirty && required && !filled;

    if (showRequiredError) {
      return (
        <Typography variant="caption" className={className} sx={{ m: 0 }}>
          {/*This field is required.*/}
        </Typography>
      );
    }

    return (
      <Typography variant="caption" className={className}>
        {children}
      </Typography>
    );
  },
)`
  display: block;
  margin-top: ${({ children }) => (children ? '4px' : '0px')};
  color: ${({ theme }) => theme.palette.semantic.negative100};
  ${({ theme }) => toCssString(theme.typography.caption)}
`;
