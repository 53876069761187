import Image from 'next/image';
import { useRouter } from 'next/router';

import { zodResolver } from '@hookform/resolvers/zod';
import { Divider } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { useTranslation } from 'next-i18next';
import { useForm } from 'react-hook-form';

import AppTextField from '@/components/TextField';
import { AppRoutes } from '@/routes';
import { to } from '@/utils/async';
import { toastError, toastSuccess } from '@/utils/notification';
import { AppButtonV2 } from '@components/Button';
import { AppLink } from '@components/Link';
import DarkLogo from '@static/assets/dark-logo.png';

import { getSignUpPayloadSchema, SignUpPayload } from './api';
import GoogleLoginBtn from './components/GoogleLoginBtn';

// NOTE: should use default axios instance, not custom axiosClient
async function signUpOnClientSide(payload: SignUpPayload) {
  await axios.post('/auth/users', payload);
}

export default function SignUpPage() {
  const { t: tErr } = useTranslation('common', {
    keyPrefix: 'error',
  });
  const { t: tCommon } = useTranslation('common');
  const { t, i18n } = useTranslation('common', {
    keyPrefix: 'page-sign-up',
    nsMode: 'fallback',
  });

  const router = useRouter();

  // required maintainable callback url for keep action join team
  const { callbackUrl, email } = router.query;
  const cbUrl = callbackUrl?.toString() || AppRoutes.INDEX;

  const {
    handleSubmit,
    control,
    formState: { isSubmitting, errors: formErrors },
    setError,
  } = useForm<SignUpPayload>({
    mode: 'onChange',
    defaultValues: { email: typeof email === 'string' ? email : undefined },
    resolver: zodResolver(getSignUpPayloadSchema(i18n)),
  });

  const onSubmit = async (data: SignUpPayload) => {
    const [, apiErr, unknownErr] = await to(() =>
      signUpOnClientSide({
        email: data.email,
        username: data.email.slice(0, data.email.indexOf('@')),
      }),
    );

    if (unknownErr) {
      const errMsg = tErr('unknown');
      setError('email', {
        message: errMsg,
      });
      toastError(errMsg);
      return;
    }

    if (apiErr) {
      const errMsg = tErr(apiErr.errorCode);
      setError('email', {
        message: errMsg,
      });
      toastError(errMsg);
      return;
    }

    toastSuccess(t('sign-up-success-message'));

    await router.push({
      pathname: AppRoutes.OTP,
      query: {
        email: data.email,
        callbackUrl: cbUrl,
      },
    });
  };

  return (
    <Stack width={352} direction="column" gap="32px">
      <Stack direction="column" alignItems="center" gap="8px">
        <Image src={DarkLogo} alt="Endash logo" width={24} height={24} />
        <Typography variant="h2" color="text.0" mt="8px">
          {t('sign-up-to-endash')}
        </Typography>
        <Typography variant="body2" color="neutralV2.2">
          {t('welcome-message')}
        </Typography>
      </Stack>

      <Stack
        bgcolor="white"
        sx={(theme) => ({
          boxShadow: `${theme.shadows[4]}`,
        })}
        borderRadius="8px"
        border="1px solid"
        borderColor="neutralV2.5"
        padding="16px"
        gap="16px"
        //
      >
        <GoogleLoginBtn callbackUrl={cbUrl} />

        <Divider
          sx={{
            flex: 1,
            borderColor: 'neutralV2.6',
            height: '2px',
          }}
        />

        <Stack component="form" gap="8px" onSubmit={handleSubmit(onSubmit)}>
          <AppTextField
            disabled={isSubmitting}
            placeholder="email@endash.ai"
            name="email"
            control={control}
            error={formErrors['email'] != null}
            helperText={formErrors['email']?.message}
          />

          <AppButtonV2
            type="submit"
            variant="contain"
            color="tertiary"
            sx={{ display: 'flex', justifyContent: 'center', padding: '9px' }}
            disabled={isSubmitting}
            //
          >
            <Typography variant="h5" color="inherit">
              {isSubmitting ? tCommon('loading') : t('continue-with-email')}
            </Typography>
          </AppButtonV2>
        </Stack>
      </Stack>

      <Box display="flex" justifyContent="center" mb={2}>
        <Typography variant="subhead5" color="neutralV2.25">
          {t('already-have-an-account')}
        </Typography>
        &nbsp;
        <Typography
          variant="h5"
          color="#2539BE"
          component={AppLink}
          href={router.asPath.replace(AppRoutes.SIGNUP, AppRoutes.SIGNIN)}
          sx={{ textDecoration: 'none' }}>
          {t('log-in')}
        </Typography>
      </Box>
    </Stack>
  );
}
