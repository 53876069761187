import { PropsWithChildren, ReactElement, useEffect, useState } from 'react';

import Image from 'next/image';
import { usePathname, useRouter } from 'next/navigation';

import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { signOut, useSession } from 'next-auth/react';
import { useTranslation } from 'react-i18next';

import { AppButtonV2 } from '@/components/Button';
import { AppRoutes } from '@/routes';
import { NextPageWithLayout } from '@/types';
import Cigro2LogoWithTextSrc from '@static/assets/logo-with-text.png';

const SignOutImage = () => {
  const { prefetch, replace } = useRouter();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    prefetch(AppRoutes.SIGNIN);
  }, []);

  return (
    <Box
      display="block"
      height={40}
      sx={[loading ? { opacity: 0.5 } : { cursor: 'pointer' }]}
      onClick={async () => {
        try {
          setLoading(true);
          await signOut({ redirect: false });
          replace(AppRoutes.SIGNIN);
        } finally {
          setLoading(false);
        }
      }}
      //
    >
      <Image
        src={Cigro2LogoWithTextSrc}
        height={24}
        alt="Endash logo with text"
        priority
      />
    </Box>
  );
};

const AuthSharedLayout = ({ children }: PropsWithChildren) => {
  const { t } = useTranslation('common', {
    keyPrefix: 'page-team-invitation-accept',
  });

  const pathname = usePathname();

  const { data: session } = useSession();

  const isLoginPage = pathname?.includes(AppRoutes.SIGNIN);
  const isSignUpPage = pathname?.includes(AppRoutes.SIGNUP);

  const isAuthenticated =
    !!session?.user && !session?.error && !isLoginPage && !isSignUpPage;

  return (
    <Box
      sx={{
        display: 'flex',
        height: 'max-content',
        minHeight: '100%',
        bgcolor: 'neutralV2.6',
      }}
      //
    >
      <Stack flex="1 1 70%" position="relative">
        <Box
          position="absolute"
          top={0}
          left={0}
          right={0}
          p="24px 40px"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          //
        >
          {isAuthenticated ? (
            <AppButtonV2
              variant="text"
              color="tertiary"
              sx={{
                backgroundColor: 'transparent',
                cursor: 'pointer',
              }}
              onClick={() => {
                signOut({
                  callbackUrl: `${AppRoutes.SIGNIN}?email=${session?.user?.email}`,
                  redirect: true,
                });
              }}>
              {t('logout')}
            </AppButtonV2>
          ) : (
            <SignOutImage />
          )}

          {isAuthenticated && (
            <Stack direction="column" gap="4px">
              <Typography variant="subhead6" color="neutralV2.2">
                {t('logged-in-as')}
              </Typography>
              <Typography variant="h5" color="neutralV2.0">
                {session?.user?.email}
              </Typography>
            </Stack>
          )}
        </Box>

        <Stack height="100%" justifyContent="center" alignItems="center">
          {children}
        </Stack>
      </Stack>

      {/* <CardMediaSwiper /> */}
    </Box>
  );
};

export function withAuthLayout(Page: NextPageWithLayout) {
  Page.getLayout = function getLayout(page: ReactElement) {
    return <AuthSharedLayout>{page}</AuthSharedLayout>;
  };
  return Page;
}
