import { useEffect } from 'react';

import Image from 'next/image';
import { useRouter } from 'next/router';

import { zodResolver } from '@hookform/resolvers/zod';
import { Divider } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { signIn } from 'next-auth/react';
import { useTranslation } from 'next-i18next';
import { useForm } from 'react-hook-form';

import AppIcon from '@/components/Icon';
import AppTextField from '@/components/TextField';
import { AppRoutes } from '@/routes';
import { to } from '@/utils/async';
import { toastError } from '@/utils/notification';
import { AppButtonV2 } from '@components/Button';
import { AppLink } from '@components/Link';
import DarkLogo from '@static/assets/dark-logo.png';

import { getSignInPayloadSchema, SignInPayload } from './api';
import GoogleLoginBtn from './components/GoogleLoginBtn';

export default function LoginPage({
  showExpirationAlert = false,
}: {
  showExpirationAlert?: boolean;
}) {
  const { t: tErr } = useTranslation('common', {
    keyPrefix: 'error',
  });
  const { t: tCommon } = useTranslation('common');
  const { t, i18n } = useTranslation('common', {
    keyPrefix: 'page-sign-in',
    nsMode: 'fallback',
  });

  const router = useRouter();
  const { callbackUrl, email } = router.query;
  const cbUrl = callbackUrl?.toString() || AppRoutes.INDEX;
  const finalCbUrl = cbUrl !== AppRoutes.SIGNIN ? cbUrl : AppRoutes.INDEX;

  const {
    handleSubmit,
    control,
    setError,
    setValue,
    formState: { isLoading, isDirty, isSubmitting, errors, isSubmitSuccessful },
  } = useForm<SignInPayload>({
    defaultValues: {
      email: typeof email === 'string' ? email : undefined,
    },
    resolver: zodResolver(getSignInPayloadSchema(i18n)),
    mode: 'onChange',
  });

  const onSubmit = async (data: SignInPayload) => {
    await to(() =>
      signIn('credentials', {
        email: data.email,
        callbackUrl: finalCbUrl,
      }),
    );
  };

  // NOTE: error route query handle
  useEffect(() => {
    if (!router.isReady) return;

    const { error, email }: { error?: string; email?: string } = router.query;

    if (email) {
      setValue('email', email.toString(), {
        shouldDirty: true,
        shouldValidate: true,
        shouldTouch: true,
      });
    }

    const apiDecodedErrorMsg = tErr(error as any, { defaultValue: '' });

    if (error === '40201' || error === '40101') {
      setError('email', {
        message: apiDecodedErrorMsg,
      });
    }

    // NOTE: normal error, skipped
    if (error === 'SessionRequired') {
      return;
    }

    if (error) {
      setError('root', {
        message: 'Server error!',
      });

      if (apiDecodedErrorMsg != null) {
        // NOTE: error = 'Callback' mean user cancel login google
        if (error !== 'Callback') toastError(apiDecodedErrorMsg);
      }

      return;
    }
  }, [tErr, setError, router.isReady, router.query, setValue]);

  return (
    <Stack width={352} direction="column" gap="32px">
      {showExpirationAlert ? (
        <Box
          sx={{
            mb: '40px',
            display: 'flex',
            padding: '8px 20px 8px 16px',
            alignItems: 'center',
            gap: '12px',
            alignSelf: 'stretch',
            borderRadius: '8px',
            border: (t) => `1px solid ${t.palette.semantic.neutral}`,
            bgcolor: 'semantic.neutral5',
          }}
          //
        >
          <AppIcon name="ic_time_out_44" size={44} />
          <Stack>
            <Typography variant="body2" color="text.0">
              {t('your-session-has-expired')}
            </Typography>
            <Typography variant="body2" color="text.0">
              {t('please-sign-in-again')}
            </Typography>
          </Stack>
        </Box>
      ) : null}

      <Stack direction="column" alignItems="center" gap="8px">
        <Image src={DarkLogo} alt="Endash logo" width={24} height={24} />
        <Typography variant="h2" color="text.0" mt="8px">
          {t('log-in-to-endash')}
        </Typography>
        <Typography variant="body2" color="neutralV2.2">
          {t('welcome-message')}
        </Typography>
      </Stack>

      <Stack
        bgcolor="white"
        sx={(theme) => ({
          boxShadow: `${theme.shadows[4]}`,
        })}
        borderRadius="8px"
        border="1px solid"
        borderColor="neutralV2.5"
        padding="16px"
        gap="16px"
        //
      >
        <GoogleLoginBtn callbackUrl={finalCbUrl} />

        <Divider
          sx={{
            flex: 1,
            borderColor: 'neutralV2.6',
            height: '2px',
          }}
        />

        <Stack component="form" gap="8px" onSubmit={handleSubmit(onSubmit)}>
          <AppTextField
            disabled={isSubmitting || isLoading}
            placeholder="email@endash.ai"
            name="email"
            control={control}
            error={errors['email'] != null}
            helperText={errors['email']?.message}
          />

          <AppButtonV2
            type="submit"
            variant="contain"
            color="tertiary"
            disabled={isSubmitting || isLoading}
            sx={{ display: 'flex', justifyContent: 'center', padding: '9px' }}
            //
          >
            <Typography variant="h5" color="inherit">
              {!isSubmitting && !isLoading
                ? t('continue-with-email')
                : tCommon('loading')}
            </Typography>
          </AppButtonV2>
        </Stack>
      </Stack>

      <Box display="flex" justifyContent="center" mb={2}>
        <Typography variant="subhead5" color="neutralV2.25">
          {t('dont-have-account-yet')}
        </Typography>
        &nbsp;
        <Typography
          variant="h5"
          color="#2539BE"
          component={AppLink}
          href={router.asPath.replace(AppRoutes.SIGNIN, AppRoutes.SIGNUP)}
          sx={{ textDecoration: 'none' }}>
          {t('sign-up')}
        </Typography>
      </Box>
    </Stack>
  );
}
