'use client';

import React, { useEffect, useState } from 'react';

import { useFormControlContext } from '@mui/base/FormControl';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import { useTranslation } from 'next-i18next';

export const APP_FORM_CONTROL_LABEL_CLASS_NAME = 'Cg__FormControl__Label';

export const AppLabel = styled(
  ({
    children,
    className,
    inputId,
    showRequired = true,
  }: {
    showRequired?: boolean;
    children?: React.ReactNode;
    className?: string;
    inputId: string;
  }) => {
    const { t } = useTranslation('common');
    const formControlContext = useFormControlContext();
    const [dirty, setDirty] = useState(false);

    useEffect(() => {
      if (formControlContext?.filled) {
        setDirty(true);
      }
    }, [formControlContext]);

    if (formControlContext === undefined) {
      return (
        <Typography variant="subhead5" color="neutralV2.2">
          {children}
        </Typography>
      );
    }

    const { error, required, filled } = formControlContext;
    const showRequiredError = dirty && required && !filled;

    return (
      <Stack
        component="label"
        htmlFor={inputId}
        flexDirection="row"
        alignItems="center"
        className={clsx(
          APP_FORM_CONTROL_LABEL_CLASS_NAME,
          className,
          error || showRequiredError ? 'Cigro-invalid' : '',
        )}
        gap="4px">
        <Typography
          variant="subhead5"
          color="neutralV2.2"
          sx={{ textTransform: 'none' }}>
          {children}
        </Typography>
      </Stack>
    );
  },
)`
  margin-bottom: ${({ children }) => (children ? '8px' : '0px')};
`;
