'use client';

import {
  Input as InputUnstyled,
  inputClasses as inputUnstyledClasses,
} from '@mui/base/Input';
import { inputAdornmentClasses } from '@mui/material/InputAdornment';
import { styled } from '@mui/material/styles';

import { toCssString } from '@/utils/styles';

export const APP_FORM_CONTROL_INPUT_ROOT_CLASS_NAME =
  'Cg__FormControl__InputRoot';

export const AppInput = styled(InputUnstyled, {
  shouldForwardProp: (propsName) => {
    if (propsName === 'sx') return false;
    return true;
  },
})`
  &.${inputUnstyledClasses.error} input {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.palette.semantic.negative100} !important;
  }

  &.${inputUnstyledClasses.adornedEnd} {
    position: relative;

    input {
      padding-right: 28px;
    }

    .${inputAdornmentClasses.root} {
      position: absolute;
      right: 8px;
      top: 50%;
      transform: translateY(-50%);
      color: ${({ theme }) => theme.palette.text[2]};
      ${({ theme }) => toCssString(theme.typography.caption2)}
    }

    .Cigro-exceeded {
      color: ${({ theme }) => theme.palette.semantic.negative100} !important;
    }
  }

  .${inputUnstyledClasses.input} {
    width: 100%;
    padding: 9px 8px;
    border: none;
    outline: none;
    border-radius: 8px;
    color: ${({ theme }) => theme.palette.text[0]};
    box-shadow: 0 0 0 1px ${({ theme }) => theme.palette.neutralV2[4]};
    caret-color: ${({ theme }) => theme.palette.text[0]};
    background-color: ${({ theme }) => theme.palette.neutralV2[8]};
    ${({ theme }) => toCssString(theme.typography.body2)};

    &:not(.${inputUnstyledClasses.disabled}) {
      :hover {
        box-shadow: 0 0 0 1px ${({ theme }) => theme.palette.neutralV2[25]};
      }
      :focus {
        box-shadow: 0 0 0 1px ${({ theme }) => theme.palette.neutralV2[2]};
      }

      &::placeholder {
        color: ${({ theme }) => theme.palette.neutralV2[25]};
      }
    }
  }
`;
