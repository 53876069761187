import { useEffect, useRef } from 'react';

import { NOOP_FN } from '@/constants';

function useTimeout(callback: () => void, timeout: number | null) {
  const savedCallback = useRef(NOOP_FN);

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    if (timeout == null) {
      return NOOP_FN;
    }

    let id = setTimeout(tick, Math.max(0, timeout));
    return () => clearTimeout(id);
  }, [timeout]);
}

export default useTimeout;
