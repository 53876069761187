import React, { useCallback, useEffect, useState } from 'react';

import Image from 'next/image';
import { useRouter } from 'next/router';

import { zodResolver } from '@hookform/resolvers/zod';
import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { signIn } from 'next-auth/react';
import { useTranslation } from 'next-i18next';
import { useForm, useWatch } from 'react-hook-form';

import { AppLink } from '@/components/Link';
import { AppFormControl } from '@/components/TextField';
import AppOtpInput from '@/feature-auth/otp/OtpInput.component';
import useTimeout from '@/hooks/use-timeout';
import { AppRoutes } from '@/routes';
import { to } from '@/utils/async';
import { toastError, toastSuccess } from '@/utils/notification';
import { isValidEmail } from '@/utils/string';
import { AppButtonV2 } from '@components/Button';
import DarkLogo from '@static/assets/dark-logo.png';

import { getSignInPayloadSchema, SignInPayload } from '../api';

const OTP_INPUT_LENGTH = 5;

const THROTTLE_MS = 10000;

// NOTE: should use default axios instance, not custom axiosClient
async function signInOnClientSide({ email, otp_code }: SignInPayload) {
  await axios.post('/auth/users/tokens', { email, otp_code });
}

export default function OtpPage({
  email,
  callbackUrl,
}: {
  email: string;
  callbackUrl?: string;
}) {
  const router = useRouter();
  const { t: tCommon } = useTranslation('common');
  const { t: tErr } = useTranslation('common', {
    keyPrefix: 'error',
  });
  const { t, ready, i18n } = useTranslation('common', {
    keyPrefix: 'page-otp',
  });

  const [isLoading, setLoading] = useState(false);
  const {
    handleSubmit,
    setValue,
    setError,
    formState: {
      isSubmitting,
      errors,
      isDirty,
      isLoading: isFormLoading,
      isSubmitSuccessful,
      isValid,
    },
    control,
  } = useForm<SignInPayload>({
    resolver: zodResolver(getSignInPayloadSchema(i18n)),
    values: {
      email,
    },
  });
  const otp = useWatch({ control, name: 'otp_code' });

  const checkIsNum = (value: string) => {
    return /^[0-9]*$/.test(value);
  };

  const [preventResend, setPreventResend] = useState(false);

  useTimeout(
    () => {
      setPreventResend(false);
    },
    preventResend ? THROTTLE_MS : null,
  );

  const validEmail = isValidEmail(email);

  const toastInvalidEmail = useCallback(() => {
    if (!ready) return;
    toastError(
      <Stack flexDirection="row" width="100%">
        <Typography component="span" variant="body1">
          {t('invalid-email')}
        </Typography>
        &nbsp;
        <Typography
          variant="body1"
          color="primary"
          component={AppLink}
          href={AppRoutes.SIGNIN}>
          {t('login')}
        </Typography>
      </Stack>,
      {
        preventDuplicate: true,
        autoHideDuration: 10000,
      },
    );
  }, [t, ready]);

  const onSubmit = async (data: SignInPayload) => {
    const errMsg = t('invalid-submit-payload');

    if (!data.email || !data.otp_code) {
      setError('otp_code', { message: errMsg });
      toastError(errMsg);
      return;
    }
    if (!checkIsNum(data.otp_code)) {
      setValue('otp_code', '');
      setError('otp_code', { message: errMsg });
      toastError(tErr('40001'));
      return;
    }

    const callbackUrlStr = callbackUrl?.toString() || AppRoutes.INDEX;
    const finalCbUrl =
      !callbackUrlStr.includes(AppRoutes.SIGNIN) &&
      !callbackUrlStr.includes(AppRoutes.OTP)
        ? callbackUrlStr
        : AppRoutes.INDEX;

    await to(() =>
      signIn('credentials', {
        ...data,
        callbackUrl: finalCbUrl,
      }),
    );
  };

  const onResendHandler = useCallback(
    async (e: React.MouseEvent) => {
      try {
        e.preventDefault(); // NOTE: prevent navigation since user clicked on a link
        setLoading(true);
        const [, apiErr, unknownErr] = await to(() =>
          signInOnClientSide({ email }),
        );
        if (unknownErr != null) {
          toastError(tErr('unknown', { code: 'cannot-resend-otp' }));
          return;
        }
        if (apiErr != null) {
          toastError(tErr(apiErr.errorCode, { code: 'cannot-resend-otp' }));
          return;
        }
        setPreventResend(true);
        toastSuccess(t('resend-success'));
      } finally {
        setLoading(false);
      }
    },
    [email, tErr, t],
  );

  // NOTE: error route query handle
  useEffect(() => {
    //
    if (!router.isReady) return;
    const { error }: { error?: string; email?: string } = router.query;

    const apiDecodedErrorMsg = tErr(error as any, { defaultValue: '' });

    if (error === '40201' || error === '40101' || !validEmail) {
      toastInvalidEmail();
      return;
    }

    if (error === '40001') {
      setError('otp_code', {
        message: apiDecodedErrorMsg,
      });
    }

    if (error) {
      setError('root', {
        message: 'Server error!',
      });
      toastError(
        apiDecodedErrorMsg != null
          ? apiDecodedErrorMsg
          : tErr('unknown', { code: 'cannot-verify-otp' }),
      );
      return;
    }
  }, [
    router.isReady,
    router.query,
    setValue,
    setError,
    tErr,
    toastInvalidEmail,
    validEmail,
  ]);

  const isGeneralLoading = isSubmitting || isLoading || isFormLoading;
  const shouldPreventSubmission =
    !isDirty || isGeneralLoading || isSubmitSuccessful;

  return (
    <Box width={352}>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <Stack gap="8px" textAlign="center" alignItems={'center'}>
          <Image src={DarkLogo} alt="Endash logo" width={24} height={24} />
          <Typography variant="h2" mt="8px">
            {t('heading')}
          </Typography>
          <Typography variant="body2" color="neutralV2.2">
            {t('please-check-your-inbox-for-verification')}
          </Typography>
        </Stack>

        <Box mt="32px" textAlign="center">
          <Stack
            bgcolor="white"
            padding="16px"
            borderRadius="8px"
            gap="24px"
            sx={{
              boxShadow: (t) => `${t.shadows[4]}`,
            }}
            //
          >
            <AppFormControl
              error={errors['otp_code'] != null}
              helperText={errors['otp_code']?.message}
              renderInput={() => (
                <AppOtpInput
                  disabled={!validEmail || isSubmitting}
                  length={OTP_INPUT_LENGTH}
                  value={otp}
                  onChange={(value) => {
                    setValue('otp_code', value, {
                      shouldDirty: true,
                      shouldTouch: true,
                      shouldValidate: true,
                    });

                    if (value.length === OTP_INPUT_LENGTH) {
                      handleSubmit(onSubmit)();
                    }
                  }}
                />
              )}
            />

            <AppButtonV2
              type="submit"
              disabled={shouldPreventSubmission || !isValid}
              variant="contain"
              color="tertiary"
              sx={{ padding: '9px', justifyContent: 'center' }}
              //
            >
              <Typography variant="h5" color="inherit">
                {isGeneralLoading ? tCommon('loading') : t('button-label-next')}
              </Typography>
            </AppButtonV2>
          </Stack>

          <Stack gap={2} mt="32px" textAlign="center">
            <Typography variant="body2" color="text.2">
              {t('description-get-code')}&nbsp;
              <Typography
                variant="button"
                color="#2539BE"
                sx={{
                  display: 'inline',
                  textTransform: 'none',
                  cursor: isGeneralLoading
                    ? 'progress'
                    : preventResend
                    ? 'not-allowed'
                    : 'pointer',
                  opacity: preventResend ? 0.5 : 1,
                }}
                onClick={!preventResend ? onResendHandler : undefined}
                //
              >
                {t('link-click-here')}
              </Typography>
              &nbsp;
              {t('description-to-resend')}
            </Typography>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
}
